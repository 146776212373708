import { MenuSections } from './Menu'

export const FunctionalServiceMenu: MenuSections = [
  {
    name: 'SAP Finance',
    column: 1,
    sub_sections: [
      {
        name: 'SAP Cash & Treasury',
//        description: 'SAP Cash & Treasury',
        link: 'functional-services',
      },
      {
        name: 'Receivables Management',
//        description: 'Receivables Management',
        link: 'functional-services',
      },
      {
        name: 'Electronic Payments',
//        description: 'Electronic Payments',
        link: 'functional-services',
      },
    ],
  },
]

export const InfrastructureAndTechnology: MenuSections = [
  {
    name: 'SAP Technology',
    column: 1,
    sub_sections: [
      {
        name: 'SAP Business Technology Platform',
        description: 'Leverage SAP Cloud offering to your advantage',
      },
      {
        name: 'SAP Fiori Enablement',
        description: 'Apps activation, SAP Gateway configuration & Performance tunning',
      },
      {
        name: 'SAP Add-Ons',
        description: 'Your own SAP certified Add-ON to take your business to the next level',
        link: 'development',
      },
    ],
  },
  {
    name: 'SAP Infrastructure',
    column: 2,
    sub_sections: [
      {
        name: 'SAP Integration & Security',
        description: 'Integrate your On-Prem & Cloud solutions, SSO, SAP Cloud Connector',
      },
      {
        name: 'S/4HANA Migration',
        description: 'Plan your path to the next generation ERP',
      },
      {
        name: 'SAP Rapid Deployments Images',
        description: 'Ready to go SAP solutions',
      },
    ],
  },
  {
    name: 'Cloud',
    column: 3,
    sub_sections: [
      {
        name: 'SAP on GCP',
        description: 'Manage your SAP landscape on Google Cloud Platform',
      },
      {
        name: 'Cloud Migration',
        description: 'Reduce cost and increase the quality of your Operations',
      },
      {
        name: 'Cloud Cost Advisory',
        description: 'Making the best out of your cloud investment',
      },
    ],
  },
]

export const ALMMenu: MenuSections = [
  {
    name: 'SAP Solution Manager',
    column: 1,
    sub_sections: [
      {
        name: 'Process Management',
        //description: 'Process Management',
        //link: 'home',
      },
      {
        name: 'Change Management',
        //description: 'Process Management',
        //link: 'home',
      },
      {
        name: 'ITSM',
        //description: 'Process Management',
        //link: 'home',
      },
      {
        name: 'Test Management',
        //description: 'Process Management',
        //link: 'home',
      },
      {
        name: 'Focused Solutions',
        //description: 'Process Management',
        //link: 'home',
      },
      {
        name: 'Monitoring',
        //description: 'Process Management',
        //link: 'home',
      },
      {
        name: 'System Integration & Custom Development',
        //description: 'Process Management',
        //link: 'home',
      },
    ]
  },
    { name: 'Micro Focus for SAP Modernization',
    column: 2,
    sub_sections: [
      {
        name: 'Micro Focus UFT',
        description: 'Functional Testing for SAP GUI & Fiori Apps',
        //link: 'home',
      },
      {
        name: 'Micro Focus LoadRunner',
        description: 'Performance Testing',
        //link: 'home',
      },
      {
        name: 'Micro Focus Fortify',
        description: 'Secure SAP App Development',
        //link: 'home',
      },
      {
        name: 'Micro Focus Connect',
        description: 'Integrate Micro Focus products with SAP Solution Manager',
        //link: 'home',
      },
    ],
  },
]

export const Development: MenuSections = [
  {
    name: 'SAP . Next Gen',
    column: 1,
    sub_sections: [
      {
        name: 'SAP BTP Solutions',
        description: 'Powerful cloud native solutions fully integrated on SAP technology stack',
        link: 'development',
      },
      {
        name: 'SAP Fiori Apps',
        description: 'Unlock the power of SAP newest UX & Design language',
        link: 'development',
      },
      {
        name: 'SAP Side by Side Extensions',
        description: 'Extend existing SAP Solutions (On-Prem/Cloud) and maximize your output',
        link: 'development',
      },
      {
        name: 'SAP Integrations & Automation',
        description: 'Integration & Automation as drivers for the ultimate user/system efficiency',
        link: 'development',
      },
      {
        name: 'SAP Add-Ons',
        description: 'Your own SAP certified Add-ON to take your business to the next level',
        link: 'development',
      },
    ],
  },
  {
    name: 'Cloud',
    column: 2,
    sub_sections: [
      {
        name: 'Solution Architecture',
        description: 'Craft requirements into technically sound solutions',
      },
      {
        name: 'Cloud Native',
        description: 'Empower your solutions by leveraging a modern application development',
      },
      {
        name: 'App Modernization',
        description: 'Evolve your legacy technology stack to a future-proof one',
      },
      {
        name: 'GCP Cortex Framework',
        description: 'Innovative SAP solutions using GCP technologies',
      },      
    ],
  },
  {
    name: 'ServiceNow',
    column: 3,
    sub_sections: [
      {
        name: 'SAP Integrations',
        description: 'Bringing together 2 worlds by increasing your ROI'
      },
      {
        name: 'Custom Development',
        description: 'Enhance your ServiceNow solutions'
      },      
    ],
  }, 
  {
    name: 'Mobile Apps',
    column: 3,
    sub_sections: [
      {
        name: 'Tailored Mobile Apps',
        description: 'Mobilizing your systems with ease of use and a delightful UX',
      },
      {
        name: 'SAP Mobile Solutions',
        description: 'Solutions to mobilize your SAP investment',
      },
    ],
  },  
/*  {
    name: 'Conventional SAP Technology',
    sub_sections: [
      {
        name: 'OS/DB Migration',
        description: 'Classical',
      },
      {
        name: 'Enhancement Packages Implementation',
      },
      {
        name: 'System Monitoring',
        description: 'Solution Manager',
      },
    ],
  },*/
]

export const StrategicConsulting: MenuSections = [
  {
    name: 'Strategic Consulting',
    column: 1,
    sub_sections: [
      {
        name: 'SAP Product Development',
        description: 'Craft your own certified solutions',
//        link: 'home',
      },
      {
        name: 'SAP Center of Excellence',
        description: 'Be the best at what you do',
//        link: 'home',
      },
      {
        name: 'Technology Roadmap',
        description: 'Navigate the future of your enterprise',
//        link: 'home',
      },
      {
        name: 'Competitive Analysis',
        description: 'Understand in detail the different solutions in the market',
//        link: 'home',
      },            
    ],
  },
]

export const Company: MenuSections = [
  {
    name: 'Company',
    column: 1,
    sub_sections: [
      {
        name: 'Partnerships',
        //description: 'Strategic Consulting',
      },
      {
        name: 'Core Values',
        //description: 'Strategic Consulting',
      },
      {
        name: 'Management',
        //description: 'Strategic Consulting',
      },
      {
        name: 'About Us',
        //description: 'Strategic Consulting',
      },
    ],
  },
]


/*
export const InfrastructureAndTechnology: MenuSections = [
  {
    name: 'Cloud Services',
    column: 1,
    sub_sections: [
      {
        name: 'Cloud SSO Integration',
        description: 'SAP BTP, SAP SAC, SAP Concur, SAP Enable Now & Others',
      },
      {
        name: 'Cloud Migration to S4',
        description: 'GCP (*) / Azure / AWS',
      },
      {
        name: 'Cloud Cost Advisory',
        description: 'Use cloud tools to enhance operation costs / Autoomation tools',
      },
      {
        name: 'Cloud Security',
        description: 'Assesment propio: No Monkey / OnapsisS',
      },
      {
        name: 'Rapid PoC Deployment',
        description: 'Templates for LAB / SAP Fully Activate Appliance deployment',
      },
    ],
  },
  {
    name: 'S/4 Hana Transformation',
    column: 2,
    sub_sections: [
      {
        name: 'Cloud Migration to S4',
        description: 'GCP (*) / Azure / AWS',
      },
      {
        name: 'Green field implementation',
        description: 'Architecture design',
      },
      {
        name: 'ERP Business Suite to S4 transformation',
        description: 'DMO',
      },
    ],
  },
  {
    name: 'Sap Fiori Enablement',
    column: 2,
    sub_sections: [
      {
        name: 'Performance Tunning',
      },
      {
        name: 'SAP Fiori Apps Activation',
      },
      {
        name: 'Gateway Configuration',
      },
    ],
  },
  {
    name: 'Conventional SAP Technology',
    column: 3,
    sub_sections: [
      {
        name: 'OS/DB Migration',
        description: 'Classical',
      },
      {
        name: 'Enhancement Packages Implementation',
      },
      {
        name: 'System Monitoring',
        description: 'Solution Manager',
      },
    ],
  },
]*/
/*
export const ALMMenu: MenuSections = [
  {
    name: 'ALM',
    column: 1,
    sub_sections: [
      {
        name: 'ALM',
        description: 'ALM',
        link: 'alm',
      },
    ],
  },
]

export const Development: MenuSections = [
  {
    name: 'Development',
    column: 1,
    sub_sections: [
      {
        name: 'Development',
        description: 'Development',
        link: 'home',
      },
    ],
  },
]

export const StrategicConsulting: MenuSections = [
  {
    name: 'Strategic Consulting',
    column: 1,
    sub_sections: [
      {
        name: 'Strategic Consulting',
        description: 'Strategic Consulting',
        link: 'home',
      },
    ],
  },
]
*/