import React from 'react'
import styled from 'styled-components'
import uiStrings from 'web/uiStrings'
import { Color, MaxWidth } from 'web/components/css/Styles'
import Text from 'web/components/widgets/Text'
import TalkToOurExperts from 'web/components/TalkToOurExperts'

import HowCanWeHelpImage from 'web/resources/img/how-can-we-help.png'

export default function HowCanWeHelp() {
  return (
    <>
      <HowCanWeHelpBackgound>
        <HowCanWeHelpContainer>
          <HowCanWeHelpImageContainer>
            <img src={HowCanWeHelpImage} alt={uiStrings.DIFF_CONSULTING_LLC} />
          </HowCanWeHelpImageContainer>
          <HowCanWeHelpActionContainer>
            <Text type={'heading2'} weight={'bold'} status={'alt'} label={uiStrings.CONTACT.TITLE} />
            <HowCanWeHelpText status={'muted'} label={uiStrings.CONTACT.TEXT} />
            <TalkToOurExperts />
          </HowCanWeHelpActionContainer>
        </HowCanWeHelpContainer>
      </HowCanWeHelpBackgound>
    </>
  )
}

const HowCanWeHelpBackgound = styled.div`
  background-color: ${Color.Grey};
  height: 400px;
`

const HowCanWeHelpContainer = styled.div`
  max-width: ${MaxWidth};
  margin: auto;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const HowCanWeHelpImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 200px;
    margin: 0 50px;
  }
`

const HowCanWeHelpActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const HowCanWeHelpText = styled(Text)`
  margin: 10px 0 20px 0;
`
