
import React from 'react'
import styled from 'styled-components'
import uiStrings from 'web/uiStrings'
import Text from 'web/components/widgets/Text'
import { Color } from 'web/components/css/Styles'

import ScreenLanding from 'web/components/ScreenLanding'
import HowCanWeHelp from 'web/components/HowCanWeHelp'

import Background from 'web/resources/img/bg-functional-services.png'
import AboutTheSolution from 'web/components/AboutTheSolution'
import KeyBenefits from 'web/components/KeyBenefits'
import AdditionalSolution from 'web/components/AdditionalSolution'
import OurServiceOffer from 'web/components/OurServiceOffer'
import Banner from 'web/components/Banner'

export default function ReactHomeScreen({ history }: { history: History }) {
    return (
        <Screen>
            <ScreenLanding
                title={uiStrings.FUNCTIONAL_SERVICES.TITLE}
                subtitle={uiStrings.FUNCTIONAL_SERVICES.SUBTITLE}
                category={uiStrings.MENU.FUNCTIONAL_SERVICES}
                background={Background}
                lineColor={Color.LightBlue}
            />

            <AboutTheSolution
                title={uiStrings.FUNCTIONAL_SERVICES.ABOUT_THE_SOLUTION}
                itemList={
                    [
                        uiStrings.FUNCTIONAL_SERVICES.ABOUT_THE_SOLUTION_CHECK_1,
                        uiStrings.FUNCTIONAL_SERVICES.ABOUT_THE_SOLUTION_CHECK_2,
                        uiStrings.FUNCTIONAL_SERVICES.ABOUT_THE_SOLUTION_CHECK_3,
                        uiStrings.FUNCTIONAL_SERVICES.ABOUT_THE_SOLUTION_CHECK_4,
                        uiStrings.FUNCTIONAL_SERVICES.ABOUT_THE_SOLUTION_CHECK_5
                    ]}
            >
                <Text type={'paragraph'} label={uiStrings.FUNCTIONAL_SERVICES.TEXT_1} status={'alt'} />
                <Text type={'paragraph'} label={uiStrings.FUNCTIONAL_SERVICES.TEXT_2} status={'alt'} />
            </AboutTheSolution>

            <KeyBenefits title={uiStrings.FUNCTIONAL_SERVICES.KEY_BENEFITS}
                itemList={
                    [
                        uiStrings.FUNCTIONAL_SERVICES.KEY_BENEFITS_1,
                        uiStrings.FUNCTIONAL_SERVICES.KEY_BENEFITS_2,
                        uiStrings.FUNCTIONAL_SERVICES.KEY_BENEFITS_3,
                        uiStrings.FUNCTIONAL_SERVICES.KEY_BENEFITS_4,
                        uiStrings.FUNCTIONAL_SERVICES.KEY_BENEFITS_5,
                        uiStrings.FUNCTIONAL_SERVICES.KEY_BENEFITS_6,
                        uiStrings.FUNCTIONAL_SERVICES.KEY_BENEFITS_7
                    ]} />

            <AdditionalSolution title={uiStrings.FUNCTIONAL_SERVICES.ADDITIONAL_SOLUTION_TITLE}
                subtitle={uiStrings.FUNCTIONAL_SERVICES.ADDITIONAL_SOLUTION_SUBTITLE}
                itemList={
                    [
                        uiStrings.FUNCTIONAL_SERVICES.ADDITIONAL_SOLUTION_ITEM_1,
                        uiStrings.FUNCTIONAL_SERVICES.ADDITIONAL_SOLUTION_ITEM_2,
                        uiStrings.FUNCTIONAL_SERVICES.ADDITIONAL_SOLUTION_ITEM_3,
                    ]}
            >
                <Text type={'paragraph'} label={uiStrings.FUNCTIONAL_SERVICES.ADDITIONAL_SOLUTION_TEXT} status={'alt'} />
            </AdditionalSolution>

            <OurServiceOffer title={uiStrings.FUNCTIONAL_SERVICES.OUR_SERVICE_OFFER_TITLE}
                itemList={[
                    uiStrings.FUNCTIONAL_SERVICES.OUR_SERVICE_OFFER_ITEM_1,
                    uiStrings.FUNCTIONAL_SERVICES.OUR_SERVICE_OFFER_ITEM_2,
                    uiStrings.FUNCTIONAL_SERVICES.OUR_SERVICE_OFFER_ITEM_3,
                    uiStrings.FUNCTIONAL_SERVICES.OUR_SERVICE_OFFER_ITEM_4,
                    uiStrings.FUNCTIONAL_SERVICES.OUR_SERVICE_OFFER_ITEM_5,
                ]}
            >
                <Text type={'paragraph'} label={uiStrings.FUNCTIONAL_SERVICES.OUR_SERVICE_OFFER_TEXT} status={'alt'} />
            </OurServiceOffer>

            <Banner text={uiStrings.FUNCTIONAL_SERVICES.BANNER_TEXt} />

            <HowCanWeHelp />
        </Screen>
    )
}

const Screen = styled.div`
`