
import React from 'react'
import styled from 'styled-components'
import { Color, MaxWidth } from 'web/components/css/Styles'
import Text from 'web/components/widgets/Text'
import TalkToOurExperts from 'web/components/TalkToOurExperts'
import { onlyIf } from 'web/reactHelpers'

export interface LandingProps {
    background: string
    title: string
    subtitle: string
    subtitleAlt?: string
    category?: string
    lineColor?: string
}

export default function ScreenLanding({ background, title, subtitle, subtitleAlt, category, lineColor, ...props }: LandingProps) {
    return (
        <LandingBackground background={background}>
            <LandingContainer>
                <LandingSide>

                    {onlyIf(!!category,
                        <Text type="paragraph" label={category} capitalization={'uppercase'} />
                    )}

                    <Text type={'heading1'} weight={'bold'} label={title} />

                    <Divider lineColor={lineColor} />

                    <Text type={'paragraph'} label={subtitle} />

                    <SubTitleContainer>
                        <Text type={'subtitle2'} weight={'bold'} status={'info'} label={subtitleAlt || ''} />
                    </SubTitleContainer>

                    <TalkToOurExpertsContainer>
                        <TalkToOurExperts />
                    </TalkToOurExpertsContainer>
                </LandingSide>
            </LandingContainer>
        </LandingBackground>
    )
}

const DefaultDiv = styled.div``

const LandingBackground = styled<any>(DefaultDiv)`
    height: 80vh;
    padding-top: 90px;
    background: ${({ background }) => `url(${background})`};;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
`

const LandingContainer = styled.div`
    max-width: ${MaxWidth};
    display: flex;
    flex-direction: column;
    margin: auto;
    height: 100%;
`

const LandingSide = styled.div`
    padding-top: 100px;
    width: 50%;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: flex-start;
`

const Divider = styled<any>(DefaultDiv)`
    border: 1px solid ${Color.Purple};
    border-color: ${({ lineColor }) => lineColor || `${Color.Purple}`};
    width: 160px;
    margin: 50px 0;
`

const SubTitleContainer = styled.div`
    margin: 10px 0;
`

const TalkToOurExpertsContainer = styled.div`
    margin: 50px 0;
`
