import React from 'react'
import styled from 'styled-components'
import Text from 'web/components/widgets/Text'
import CheckmarkImage from 'web/resources/img/checkmark.png'
import { onlyIf } from 'web/reactHelpers'

export interface CheckProps {
    label?: string
    image?: any
    checkmarkWidth?: number
}

export default function Check({ label, image, checkmarkWidth }: CheckProps) {
    return (
        <CheckContainer checkmarkWidth={checkmarkWidth}>
            <img src={image || CheckmarkImage} alt='' />
            {onlyIf(label !== undefined, <Text type={'paragraph'} status={'alt'} weight={'semi-bold'} label={label || ''} />)}
        </CheckContainer>
    )
}

const DefaultDiv = styled.div``

const CheckContainer = styled<any>(DefaultDiv)`
    display:flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;

    margin: 5px 0;

    img {
        width: ${({ checkmarkWidth }) => `${checkmarkWidth || 15}px`};
        margin-right: 5px;
    }
`