import React from 'react'
import styled from 'styled-components'
import uiStrings from 'web/uiStrings'
import { MaxWidth } from 'web/components/css/Styles'
import Button from 'web/components/widgets/Button'
import Text from 'web/components/widgets/Text'

import Background from 'web/resources/img/bg-about-us.png'
import Image from 'web/resources/img/image-about-us.png'

export default function AboutUs() {
    return (
        <AboutUsContainer>
            <Container>
                <ImageContainer>
                    <img src={Image} alt={uiStrings.DIFF_CONSULTING_LLC} />
                </ImageContainer>
                <TextContainer>
                    <Text type={'heading2'} weight={'bold'} label={uiStrings.ABOUT_US.TITLE} />
                    <TextStyled type={'paragraph'} label={uiStrings.ABOUT_US.TEXT} />
                    <ReadMoreButton>
                        <Button label={uiStrings.READ_MORE} onClick={() => { }} />
                    </ReadMoreButton>
                </TextContainer>
            </Container>
        </AboutUsContainer>
    )
}

const AboutUsContainer = styled.div`
    background-image: url(${Background});
    height: 100vh;
    display: flex;
`

const Container = styled.div`
    max-width: ${MaxWidth};
    width: 100%;
    margin: auto;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
`

const ImageContainer = styled.div`
    flex:1;
    img {
            height: 100%;
            max-width: 450px;
        }
`

const TextContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`

const TextStyled = styled(Text)`
    margin: 50px 0;
`

const ReadMoreButton = styled.div`
  margin: 20px;
`