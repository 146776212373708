export const MaxWidth = '1300px'

export const Color = {
  Purple: '#6421EA',
  PurpleAlt: '#6A4DEA',
  PurpleAltTransparent: '#6A4DEA3D',
  White: '#FFFFFF',
  WhiteAlt: '#F6F8FB',
  Black: '#000000',
  Grey: '#f7f8fb',
  GreyAlt: '#545B63',
  GreyTransparent: '#545b637d',
  Transparent: 'transparent',
  LightBlue: '#36C9FF',

  Green: 'Green',
  Red: 'Red',
}
