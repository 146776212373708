import React from 'react'
import styled from 'styled-components'
import { MaxWidth } from 'web/components/css/Styles'
import Text from 'web/components/widgets/Text'
import Check from 'web/components/widgets/Check'

import BlueCheckmark from 'web/resources/img/checkmark-blue.png'
import BackgroundTreasury from 'web/resources/img/bg-treasury.png'

export interface Props {
    title: string
    children: React.ReactNode
    subtitle: string
    itemList: Array<string>
}

export default function AdditionalSolution({ title, children, subtitle, itemList }: Props) {
    return (
        <TreasuryAndCashmanagementBackground>
            <TreasuryAndCashmanagementContainer>
                <TreasuryAndCashmanagementTitle>
                    <Text type={'heading2'} weight={'bold'} label={title} status={'alt'} />
                </TreasuryAndCashmanagementTitle>

                <TextContainer>
                    {children}
                </TextContainer>

                <ImplementationList>
                    <Subtitle type={'heading4'} label={subtitle} status={'alt'} weight={'bold'} />
                    <TreasuryAndCashmanagementCheckmarks>
                        {itemList
                            .map(item =>
                                <Check label={item} image={BlueCheckmark} />
                            )}
                    </TreasuryAndCashmanagementCheckmarks>
                </ImplementationList>
            </TreasuryAndCashmanagementContainer>
        </TreasuryAndCashmanagementBackground>
    )
}

const TreasuryAndCashmanagementBackground = styled.div`
    background: url(${BackgroundTreasury});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
`

const TreasuryAndCashmanagementContainer = styled.div`
    max-width: ${MaxWidth};
    height: 100%;
    margin:auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 30px 0;
`

const TreasuryAndCashmanagementTitle = styled.div`
    margin: 10px 0;
`

const TextContainer = styled.div`
    margin: 30px 0;
`

const Subtitle = styled(Text)`
    margin: 0 0 20px 0;
`

const ImplementationList = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 25px;
`

const TreasuryAndCashmanagementCheckmarks = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
`