const uiStrings = {
  DIFF_CONSULTING_LLC: 'Diff Consulting LLC | Business Solutions',
  TALK_TO_OUR_EXPERTS: 'Talk to our Experts!',
  PLEASE_FILL_OUT_THE_FORM: 'Please fill out the form',
  FIRST_NAME: 'First Name',
  LAST_NAME: 'Last Name',
  COMPANY_NAME: 'Company Name',
  COMPANY_EMAIL: 'Company Email',
  PHONE_NUMBER: 'Phone Number',
  MESSAGE: 'Message',
  SUBMIT: 'Submit',
  READ_MORE: 'Read more',

  MENU: {
    FUNCTIONAL_SERVICES: 'Functional Services',
    INFRASTRUCTURE_TECHNOLOGY: 'Infrastructure & Technology',
    ALM: 'ALM',
    DEVELOPMENT: 'Development',
    STRATEGIC_CONSULTING: 'Strategic Consulting',
    COMPANY: 'Company',
  },

  HOME: {
    LANDING_TITLE: 'Strategic Consulting, Business Solutions.',
    LANDING_SUBTITLE_1:
      'At Diff we offer strategic consulting services with the objective of improve your IT landscape, to simplify your business operations.',
    LANDING_SUBTITLE_2: 'Think Smart. Think Different. Think Diff.',

    BANNER_TITLE: 'We are a software specialist company.',
    BANNER_SUBTITLE: 'We focus on innovating, improving and solving your business.',

    S4HANNA_SLIDE_TITLE: 'S/4HANA Migration Services',
    S4HANNA_SLIDE_TEXT:
      'The path to S/4HANA can be challenging. Many companies today are looking for starting this process, but multiple questions arise when navigating through distinct possibilities. There are different migration options, different versions, technical challenges, specific customer situations to be conte plated.',
    S4HANNA_SLIDE_CHECK_1: 'S/4HANA Discovery',
    S4HANNA_SLIDE_CHECK_2: 'S/4HANA Adoption Strategy',
    S4HANNA_SLIDE_CHECK_3: 'System Readiness for Migratio',

    TREASURY_AND_FINANCE_TITLE: 'Treasury & Finance',
    TREASURY_AND_FINANCE_TEXT:
      'We have consultants have deep expertise in SAP Finance (GL, AR, AP, TR, Fixed Assets). Our team members have worked in numerous implementa-tions, and can assist in overall Finance submodules and functionalities.',
    TREASURY_AND_FINANCE_TEXT_ALT:
      'We can help you to transform your Treasury Operations, by deploying advanced solutions to embrace digital finance.',

    DEVELOPMENT_AND_STRUCTURE_SLIDE_TITLE: 'Development & Infrastructure',
    DEVELOPMENT_AND_STRUCTURE_SLIDE_TEXT:
      'We have a skilled developers team with more tan 20 years of experience developing in SAP landscape. Our team members have been delivering sof-tware with premium quality fo internacional customers and SAP itself.',
    DEVELOPMENT_AND_STRUCTURE_SLIDE_CHECK_1: 'SAP HANA',
    DEVELOPMENT_AND_STRUCTURE_SLIDE_CHECK_2: 'Classic ABAP',
    DEVELOPMENT_AND_STRUCTURE_SLIDE_CHECK_3: 'Other Services',
  },

  FUNCTIONAL_SERVICES: {
    TITLE: 'SAP Cash and Treasury',
    SUBTITLE:
      'Get real-time cash visibility, improve liquidity, and lower risk with reasury and risk management solutions from SAP.',

    ABOUT_THE_SOLUTION: 'About the solution',
    TEXT_1:
      'With the module FSCM, SAP offers a comprehensive solution to manage core financial aspects of your company. It goes beyond the basic offer in SAP Finance (AP, AR, Bank Processing) by providing a set of solutions that allow an integral management of financials.',
    TEXT_2:
      'The different functionalities permit better visibility of Cash and Colle-tions, Liquidity Analysis, management of Financial Instruments, Centralize Payments, among others. The submodules can be implemented separa-tely to improve specific points, but also in conjunction as part of the same project, to develop a real Treasury Transformation Program.',
    ABOUT_THE_SOLUTION_CHECK_1: 'Cash Management and Liquidity Management',
    ABOUT_THE_SOLUTION_CHECK_2: 'Treasury and Risk Management',
    ABOUT_THE_SOLUTION_CHECK_3: 'Bank Communication Management',
    ABOUT_THE_SOLUTION_CHECK_4: 'SAP In-House Cash',
    ABOUT_THE_SOLUTION_CHECK_5: 'Bank Account Management (only available for S/4HANA)',

  KEY_BENEFITS: 'Key Benefits',
  KEY_BENEFITS_1: 'Integration of multiple data sources in one platform.',
  KEY_BENEFITS_2: 'Better visibility of Cash and Collections',
  KEY_BENEFITS_3: 'Liquidity Analysis and Planning',
  KEY_BENEFITS_4: 'Management of Financial Instruments (Debt , Investment and Valuation).',
  KEY_BENEFITS_5: 'Hedge Management control',
  KEY_BENEFITS_6: 'Payments Optimization',
  KEY_BENEFITS_7: 'Streamline Intercompany Activity through In-House Cash',

  ADDITIONAL_SOLUTION_TITLE: 'Treasury & Cash Management with SAP s/4HANA',
  ADDITIONAL_SOLUTION_SUBTITLE: 'We can cover the implementation of the different solution',
  ADDITIONAL_SOLUTION_ITEM_1: 'Cash Management and Liquidity Management',
  ADDITIONAL_SOLUTION_ITEM_2: 'Treasury and Risk Management',
  ADDITIONAL_SOLUTION_ITEM_3: 'Bank Communication Management',
  ADDITIONAL_SOLUTION_TEXT:
    'The next generation solutions ( S/4HANA Treasury, S/4HANA Cash Management and S/4HANA Bank Account Management) bring the possibility of building a solid foundation for an intelligent finance program. The innovation is carried by an improved user experience with FIORI, new possibilities for reporting and analytics, and information on mobile devices. The consultants in our team have been working with these capabilities since they were released.',

  OUR_SERVICE_OFFER_TITLE: 'Our Service for Cash Management & Treasury',
  OUR_SERVICE_OFFER_ITEM_1: 'Strategic Consulting',
  OUR_SERVICE_OFFER_ITEM_2: 'Implementation and rapid Deployment Services',
  OUR_SERVICE_OFFER_ITEM_3: 'S/4HANA Demo',
  OUR_SERVICE_OFFER_ITEM_4: 'Custom Software Development',
  OUR_SERVICE_OFFER_ITEM_5: 'Real Time API Integration',
  OUR_SERVICE_OFFER_TEXT:
    'Our team has deep expertise in FSCM module. We have consultants with more than 12 years of experience working in the different solutions that SAP provides to boost financial operations at your company.  We work not only with FSCM submodules, but the integral solution that cover the end-to-end process of your Financial Chain.',

  BANNER_TEXt:
    'We can help you to transform your Treasury Operations by deploying advanced solutions to embrace digital finance.',
},

DEVELOPMENT_SERVICES: {
  TITLE: 'Development',
  SUBTITLE:
    'Get real-time cash visibility, improve liquidity, and lower risk with reasury and risk management solutions from SAP.',

  ABOUT_THE_SOLUTION: 'About the solution',
  TEXT_1:
    'With the module FSCM, SAP offers a comprehensive solution to manage core financial aspects of your company. It goes beyond the basic offer in SAP Finance (AP, AR, Bank Processing) by providing a set of solutions that allow an integral management of financials.',
  TEXT_2:
    'The different functionalities permit better visibility of Cash and Colle-tions, Liquidity Analysis, management of Financial Instruments, Centralize Payments, among others. The submodules can be implemented separa-tely to improve specific points, but also in conjunction as part of the same project, to develop a real Treasury Transformation Program.',
  ABOUT_THE_SOLUTION_CHECK_1: 'Cash Management and Liquidity Management',
  ABOUT_THE_SOLUTION_CHECK_2: 'Treasury and Risk Management',
  ABOUT_THE_SOLUTION_CHECK_3: 'Bank Communication Management',
  ABOUT_THE_SOLUTION_CHECK_4: 'SAP In-House Cash',
  ABOUT_THE_SOLUTION_CHECK_5: 'Bank Account Management (only available for S/4HANA)',

  KEY_BENEFITS: 'Key Benefits',
  KEY_BENEFITS_1: 'Integration of multiple data sources in one platform.',
  KEY_BENEFITS_2: 'Better visibility of Cash and Collections',
  KEY_BENEFITS_3: 'Liquidity Analysis and Planning',
  KEY_BENEFITS_4: 'Management of Financial Instruments (Debt , Investment and Valuation).',
  KEY_BENEFITS_5: 'Hedge Management control',
  KEY_BENEFITS_6: 'Payments Optimization',
  KEY_BENEFITS_7: 'Streamline Intercompany Activity through In-House Cash',

  ADDITIONAL_SOLUTION_TITLE: 'Treasury & Cash Management with SAP s/4HANA',
  ADDITIONAL_SOLUTION_SUBTITLE: 'We can cover the implementation of the different solution',
  ADDITIONAL_SOLUTION_ITEM_1: 'Cash Management and Liquidity Management',
  ADDITIONAL_SOLUTION_ITEM_2: 'Treasury and Risk Management',
  ADDITIONAL_SOLUTION_ITEM_3: 'Bank Communication Management',
  ADDITIONAL_SOLUTION_TEXT:
    'The next generation solutions ( S/4HANA Treasury, S/4HANA Cash Management and S/4HANA Bank Account Management) bring the possibility of building a solid foundation for an intelligent finance program. The innovation is carried by an improved user experience with FIORI, new possibilities for reporting and analytics, and information on mobile devices. The consultants in our team have been working with these capabilities since they were released.',

  OUR_SERVICE_OFFER_TITLE: 'Our Service for Cash Management & Treasury',
  OUR_SERVICE_OFFER_ITEM_1: 'Strategic Consulting',
  OUR_SERVICE_OFFER_ITEM_2: 'Implementation and rapid Deployment Services',
  OUR_SERVICE_OFFER_ITEM_3: 'S/4HANA Demo',
  OUR_SERVICE_OFFER_ITEM_4: 'Custom Software Development',
  OUR_SERVICE_OFFER_ITEM_5: 'Real Time API Integration',
  OUR_SERVICE_OFFER_TEXT:
    'Our team has deep expertise in FSCM module. We have consultants with more than 12 years of experience working in the different solutions that SAP provides to boost financial operations at your company.  We work not only with FSCM submodules, but the integral solution that cover the end-to-end process of your Financial Chain.',

  BANNER_TEXt:
    'We can help you to transform your Treasury Operations by deploying advanced solutions to embrace digital finance.',
},
ALM: {
  TITLE: 'SAP Solution Manager',
  SUBTITLE: 'This is ALM',
},
INFRASTRUCTURE_AND_TECHNOLOGY: {
  TITLE: 'Infrastructure and Technology'
},
ABOUT_US: {
  TITLE: 'About us',
  TEXT:
    'Diff was founded as a group of professionals offering SAP strategic consulting services. We have strong focus on SAP latest technologies, but we also offer services for SAP classic landscapes.',
},
CONTACT: {
  TITLE: 'Hi!',
  TEXT: 'How can we help?',
},
PARTNERS: { GOOGLE: 'Google', MICROFOCUS: 'MicroFocus', SAP: 'SAP' },
}

export default uiStrings
