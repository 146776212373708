import React from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { History } from 'history'
import uiStrings from 'web/uiStrings'
import { Color, MaxWidth } from 'web/components/css/Styles'
import Button from 'web/components/widgets/Button'
import Text from 'web/components/widgets/Text'
import Slide from 'web/screens/home/Slide'
import AboutUs from 'web/screens/home/AboutUs'
import Check from 'web/components/widgets/Check'
import HowCanWeHelp from 'web/components/HowCanWeHelp'
import TalkToOurExperts from 'web/components/TalkToOurExperts'

import Background from 'web/resources/img/bg-home.jpeg'
import Google from 'web/resources/img/google.png'
import MicroFocus from 'web/resources/img/microfocus.png'
import Sap from 'web/resources/img/sap.png'
import BackgroundBanner from 'web/resources/img/banner-background.png'
import ImgSlide1 from 'web/resources/img/slide-1.png'
import ImgSlide2 from 'web/resources/img/slide-2.png'
import ImgSlide3 from 'web/resources/img/slide-3.png'

export default function ReactHomeScreen({ history }: { history: History }) {
  return (
    <Screen>
      <ScreenLanding />

      <Banner>
        <Text type={'heading2'} weight={'bold'} label={uiStrings.HOME.BANNER_TITLE} />
        <Text type={'subtitle'} label={uiStrings.HOME.BANNER_SUBTITLE} />
      </Banner>

      <S4HannaMigrationService />
      <TreasuryAndFinance />
      <DevelopmentAndInfrastructure />

      <AboutUs />

      <HowCanWeHelp />
    </Screen>
  )
}

function ScreenLanding() {
  return (
    <LandingBackground>
      <LandingContainer>
        <LandingSide>
          <div>
            <Text type={'heading1'} weight={'bold'} label={uiStrings.HOME.LANDING_TITLE} />
          </div>

          <Divider />

          <div>
            <Text type={'paragraph'} label={uiStrings.HOME.LANDING_SUBTITLE_1} />
          </div>

          <SubTitleContainer>
            <Text type={'subtitle2'} weight={'bold'} status={'info'} label={uiStrings.HOME.LANDING_SUBTITLE_2} />
          </SubTitleContainer>

          <TalkToOurExpertsContainer>
            <TalkToOurExperts />
          </TalkToOurExpertsContainer>
        </LandingSide>

        <PartnersContainer>
          <img src={Google} alt={uiStrings.PARTNERS.GOOGLE}></img>
          <img src={MicroFocus} alt={uiStrings.PARTNERS.MICROFOCUS}></img>
          <img src={Sap} alt={uiStrings.PARTNERS.SAP}></img>
        </PartnersContainer>
      </LandingContainer>
    </LandingBackground>
  )
}

function S4HannaMigrationService() {
  const history: History = useHistory()

  return (
    <>
      <Slide image={ImgSlide1}>
        <Text type={'heading2'} weight={'bold'} status={'alt'} label={uiStrings.HOME.S4HANNA_SLIDE_TITLE} />

        <ParagraphSpacer>
          <Text type={'paragraph'} status={'alt'} label={uiStrings.HOME.S4HANNA_SLIDE_TEXT} />
        </ParagraphSpacer>

        <Check label={uiStrings.HOME.S4HANNA_SLIDE_CHECK_1} />
        <Check label={uiStrings.HOME.S4HANNA_SLIDE_CHECK_2} />
        <Check label={uiStrings.HOME.S4HANNA_SLIDE_CHECK_3} />

        <ReadMoreButton>
          <Button label={uiStrings.READ_MORE} onClick={() => { history.push('/') }} />
        </ReadMoreButton>
      </Slide>
    </>
  )
}

function TreasuryAndFinance() {
  const history: History = useHistory()

  return (
    <>
      <Slide image={ImgSlide2} mode={'color'} position={'left'} >
        <Text type={'heading2'} weight={'bold'} label={uiStrings.HOME.TREASURY_AND_FINANCE_TITLE} />

        <ParagraphSpacer>
          <Text type={'paragraph'} label={uiStrings.HOME.TREASURY_AND_FINANCE_TEXT} />
        </ParagraphSpacer>

        <Text type={'subtitle2'} label={uiStrings.HOME.TREASURY_AND_FINANCE_TEXT_ALT} />

        <ReadMoreButton>
          <Button label={uiStrings.READ_MORE} onClick={() => { history.push('/') }} />
        </ReadMoreButton>
      </Slide>
    </>
  )
}

function DevelopmentAndInfrastructure() {
  const history: History = useHistory()
  return (
    <>
      <Slide image={ImgSlide3}>
        <Text type={'heading2'} weight={'bold'} status={'alt'} label={uiStrings.HOME.DEVELOPMENT_AND_STRUCTURE_SLIDE_TITLE} />
        <ParagraphSpacer>
          <Text type={'paragraph'} status={'alt'} label={uiStrings.HOME.DEVELOPMENT_AND_STRUCTURE_SLIDE_TEXT} />
        </ParagraphSpacer>

        <Check label={uiStrings.HOME.DEVELOPMENT_AND_STRUCTURE_SLIDE_CHECK_1} />
        <Check label={uiStrings.HOME.DEVELOPMENT_AND_STRUCTURE_SLIDE_CHECK_2} />
        <Check label={uiStrings.HOME.DEVELOPMENT_AND_STRUCTURE_SLIDE_CHECK_3} />

        <ReadMoreButton>
          <Button label={uiStrings.READ_MORE} onClick={() => { history.push('/') }} />
        </ReadMoreButton>
      </Slide>
    </>
  )
}

const Screen = styled.div`
  `

const LandingBackground = styled.div`
  height: 100vh;
  padding-top: 90px;
  background: url(${Background});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`

const LandingContainer = styled.div`
  max-width: ${MaxWidth};
  display: flex;
  flex-direction: column;
  margin: auto;
  height: 100%;
`

const LandingSide = styled.div`
  padding-top: 100px;
  width: 50%;
  display: flex;
  flex-direction: column;
  height: 80%;
  justify-content: center;
  align-items: flex-start;
`

const Divider = styled.div`
  border: 1px solid ${Color.Purple};
  width: 160px;
  margin: 50px 0;
`

const SubTitleContainer = styled.div`
  margin: 10px 0;
`

const TalkToOurExpertsContainer = styled.div`
  margin: 50px 0;
`

const PartnersContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
  margin: 50px 0;
`

const Banner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 250px;
  background: url(${BackgroundBanner});
  span {
    margin: 14px 0;
  }
`

const ReadMoreButton = styled.div`
  margin: 20px;
`

const ParagraphSpacer = styled.div`
  margin: 15px 0;
`