import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { Color, MaxWidth } from 'web/components/css/Styles'
import Background from 'web/resources/img/slide-background.png'

export type SlideProps = {
    image: any;
    mode?: string
    position?: string
    children: ReactNode
}

export default function Slide({ image, position, children, ...props }: SlideProps) {
    return (
        <SlideContainer {...props}>
            <Container position={position}>
                <TextContainer>
                    {children}
                </TextContainer>
                <SlideDivider />
                <ImageContainer>
                    <img src={image} alt={'Diff'} />
                </ImageContainer>
            </Container>
        </SlideContainer>
    )
}

const UnstyledDiv = styled.div``

const SlideContainer = styled<any>(UnstyledDiv)`
    height: 680px;
    display: flex;
    ${({ mode }) => {
        if (mode === 'color')
            return `background: url(${Background})`
        else
            return `background: ${Color.White}`
    }};
`

const Container = styled<any>(UnstyledDiv)`
    height: 500px;
    width: 100%;
    max-width: ${MaxWidth};
    margin: auto;
    display: flex;
    ${({ position }) => {
        if (position === 'left')
            return `flex-direction: row-reverse;`
        else
            return `flex-direction: row;`
    }};
    align-content: center;
    justify-content: center;
    align-items: center;
`

const ImageContainer = styled.div`
    flex: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    align-content: center;
    img {
        width: 100%;
        max-width: 450px;
    }
`

const TextContainer = styled.div`
    flex: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    align-content: center;
`

const SlideDivider = styled.div`
    width: 100px;
`