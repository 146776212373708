import React from 'react'
import styled from 'styled-components'
import { Color } from 'web/components/css/Styles'

export interface ButtonProps {
    mode?: ButtonModes
    label?: string
    title?: string
    onClick?: () => void
}

export default function Button(props: ButtonProps) {
    return (
        <ButtonElement {...props}>
            {props.label}
        </ButtonElement>
    )
}

export type ButtonModes = 'normal' | 'secondary'

const UnstyledButton = styled.button`
  padding: 0;
  border: 0;
  line-height: 0;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
  outline: none;
`

const ButtonElement = styled<any>(UnstyledButton)`
    background-color: ${({ mode }: { mode: ButtonModes }) => getBackgroundColor(mode)};
    color: ${({ mode }: { mode: ButtonModes }) => getFontColor(mode)};
    ${({ mode }) => (mode === 'secondary' ? `border: ${Color.Purple} solid 1px` : '')};
    padding: 10px 25px;
    border-radius: 8px;

    :hover:not(:disabled) {
        background-color: ${({ mode }) => getHoverBackgroundColor(mode)};
    }
`

const MODES = {
    normal: {
        BACKGROUND_COLOR_DEFAULT: Color.Purple,
        BACKGROUND_COLOR_HOVERED: Color.Purple,
        FONT_COLOR: Color.White
    },
    secondary: {
        BACKGROUND_COLOR_DEFAULT: Color.White,
        BACKGROUND_COLOR_HOVERED: Color.White,
        FONT_COLOR: Color.Purple
    }
}

const getBackgroundColor = (mode: ButtonModes = 'normal') => MODES[mode].BACKGROUND_COLOR_DEFAULT
const getHoverBackgroundColor = (mode: ButtonModes = 'normal') => MODES[mode].BACKGROUND_COLOR_HOVERED
const getFontColor = (mode: ButtonModes = 'normal') => MODES[mode].FONT_COLOR