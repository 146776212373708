import React from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { History } from 'history'
import uiStrings from 'web/uiStrings'
import { Color, MaxWidth } from 'web/components/css/Styles'
import LogoDiffAlt from 'web/resources/img/logo-diff-alt.png'
import FillEmptySpaceDiv from 'web/components/FillEmptySpaceDiv'
import LinkedIn from 'web/resources/img/in.png'
import TalkToOurExperts from 'web/components/TalkToOurExperts'
import Text from 'web/components/widgets/Text'

export default function Footer() {
    const history: History = useHistory()

    return (
        <FooterContainer>
            <Container>
                <Logo><img src={LogoDiffAlt} alt='Diff' onClick={() => navigate('/', history)} /></Logo>
                <FillEmptySpaceDiv></FillEmptySpaceDiv>
                <Navigation>
                    <Option>
                        <div>
                            <Text onClick={() => navigate('functional-services', history)} label={uiStrings.MENU.FUNCTIONAL_SERVICES} type={'small'} weight={'bold'} capitalization={'uppercase'} status={'alt'} />
                        </div>
                    </Option>
                    <Option>
                        <div>
                            <Text onClick={() => navigate('functional-services', history)} label={uiStrings.MENU.INFRASTRUCTURE_TECHNOLOGY} type={'small'} weight={'bold'} capitalization={'uppercase'} status={'alt'} />
                        </div>
                    </Option>
                    <Option>
                        <div>
                            <Text onClick={() => navigate('functional-services', history)} label={uiStrings.MENU.ALM} type={'small'} weight={'bold'} capitalization={'uppercase'} status={'alt'} />
                        </div>
                    </Option>
                    <Option>
                        <div>
                            <Text onClick={() => navigate('functional-services', history)} label={uiStrings.MENU.DEVELOPMENT} type={'small'} weight={'bold'} capitalization={'uppercase'} status={'alt'} />
                        </div>
                    </Option>
                    <Option>
                        <div>
                            <Text onClick={() => navigate('functional-services', history)} label={uiStrings.MENU.STRATEGIC_CONSULTING} type={'small'} weight={'bold'} capitalization={'uppercase'} status={'alt'} />
                        </div>
                    </Option>
                    <Option>
                        <div>
                            <Text onClick={() => navigate('functional-services', history)} label={uiStrings.MENU.COMPANY} type={'small'} weight={'bold'} capitalization={'uppercase'} status={'alt'} />
                        </div>
                    </Option>

                    <TalkToOurExpertsContainer>
                        <TalkToOurExperts mode={'secondary'} />
                    </TalkToOurExpertsContainer>

                    <LinkedInContainer>
                        <img src={LinkedIn} alt='LinkedIn' onClick={() => { window.open('https://www.linkedin.com/company/diff-consulting/') }} />
                    </LinkedInContainer>
                </Navigation>
            </Container>
        </FooterContainer>
    )
}

const navigate = (path: string, history: History): void => {
    history.push(path)
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });
}

const FooterContainer = styled.div`
    height: 200px;
    background: ${Color.White};
`

const Container = styled.div`
    max-width: ${MaxWidth};
    margin: auto;
    height: 100%;
    display: flex;
    align-content: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
`

const Logo = styled.div`
    align-items: center;
    justify-content: center;
    display: flex;
    cursor: pointer;
    img {
        max-width:120px;
    }
`

const Navigation = styled.div`
    display:flex;
    align-content: center;
    justify-content: center;
    align-items: center;
`

const Option = styled.div`
    display: flex;
    align-items: center;

    margin: 0 6px;
    span {
            :hover {
            cursor: pointer;
            color: ${Color.Purple};
        }
    }
`

const TalkToOurExpertsContainer = styled.div`
    margin-left: 10px;
`

const LinkedInContainer = styled.div`
    margin-left: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`