import React from 'react'
import styled from 'styled-components'
import { Color } from 'web/components/css/Styles'

export interface FieldProps {
  label: string
  htmlFor?: string
  required?: boolean
  children: React.ReactNode
}

export default function Field({ label, htmlFor, required, children, ...props }: FieldProps) {
  const hasChildrenRequiredProperty = () =>
    React.Children.toArray(children).some(({ props }: any) => props && props.required)
  const isRequired = required || hasChildrenRequiredProperty()

  return (
    <>
      <LabelContainer htmlFor={htmlFor} {...props}>
        {label}
        {isRequired ? <RequiredSymbol>{'\u00a0*'}</RequiredSymbol> : null}
      </LabelContainer>
      {children}
    </>
  )
}

const LabelContainer = styled.label`
  display: flex;
  align-items: center;
  height: 16px;
  user-select: none;
  color: ${Color.GreyAlt};
  font-size: 13px;
  font-weight: 400;
  line-height: 1.5;
  white-space: nowrap;
  margin-bottom: 2px;
`

const RequiredSymbol = styled.span`
  color: ${Color.Red};
`
