import React from 'react'
import styled from 'styled-components'
import { Color, MaxWidth } from 'web/components/css/Styles'
import Text from 'web/components/widgets/Text'
import Box from './widgets/Box'

export interface Props {
    title: string
    children: React.ReactNode
    itemList: Array<string>
}

export default function OurServiceOffer({ title, children, itemList }: Props) {
    return (
        <Background>
            <Container>
                <OurServiceTitle>
                    <Text type={'heading2'} weight={'bold'} label={title} status={'alt'} />
                </OurServiceTitle>

                <TextContainer>
                    {children}
                </TextContainer>

                <OurServiceTitle>
                    <Text type={'heading2'} weight={'bold'} label={'Our offer to you'} status={'alt'} />
                </OurServiceTitle>

                <Boxes>
                    {itemList
                        .map(item =>
                            <Box label={item} />
                        )}
                </Boxes>
            </Container>
        </Background>
    )
}

const Background = styled.div`
    background-color: ${Color.WhiteAlt};
`

const Container = styled.div`
    max-width: ${MaxWidth};
    height: 100%;
    padding: 30px 0;
    margin:auto;
    display: flex;
    flex-direction: column;
`

const OurServiceTitle = styled.div`
    margin-bottom: 25px;
`

const TextContainer = styled.div`
    margin-bottom: 50px;
`

const Boxes = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    align-content: center;
`