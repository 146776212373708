import React from 'react'
import styled from 'styled-components'
import { Color, MaxWidth } from 'web/components/css/Styles'
import Text from 'web/components/widgets/Text'
import Check from 'web/components/widgets/Check'

import BackgroundKeyBenefits from 'web/resources/img/bg-key-benefits.png'
import SquareCheckmark from 'web/resources/img/checkmark-2.png'

export interface Props {
    title: string
    itemList: Array<string>
}

export default function KeyBenefits({ title, itemList }: Props) {
    return (
        <Background>
            <KeyBenefitsContainer>
                <KeyBenefitsContent>
                    <Text type={'heading2'} weight={'bold'} label={title} status={'alt'} />
                    <Divider />
                    <KeyBenefitsCheckmarks>
                        {itemList
                            .map(item =>
                                <Check label={item} image={SquareCheckmark} checkmarkWidth={10} />
                            )}
                    </KeyBenefitsCheckmarks>
                </KeyBenefitsContent>
            </KeyBenefitsContainer>
        </Background>
    )
}

const Background = styled.div`
    background: url(${BackgroundKeyBenefits});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 500px;
    position: relative;
    margin-top: 50px;
`

const KeyBenefitsContainer = styled.div`
    width: 100%;
    height: 100%;
    max-width: ${MaxWidth};
    margin: auto;
    position: relative;
`

const KeyBenefitsContent = styled.div`
    height: 100%;
    width: 50%;
    padding: 50px 30px;
    background: white;
    display: flex;
    flex-direction: column;
    position: absolute;
    margin-top: -50px;
    border: 1px solid #6b6b6b17;
    box-shadow: 6px 5px 5px #6b6b6b85;
`
const DefaultDiv = styled.div``

const Divider = styled<any>(DefaultDiv)`
    border: 1px solid ${Color.Purple};
    border-color: ${Color.LightBlue};
    width: 160px;
    margin: 20px 0;
`
const KeyBenefitsCheckmarks = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-evenly;
    flex: 1;
`