import React from 'react'
import styled from 'styled-components'
import { Color } from 'web/components/css/Styles'

export interface TextProps {
    label: string | undefined
    type?: string
    weight?: string
    status?: string
    capitalization?: string
    onClick?: () => void
    onMouseEnter?: () => void
    onMouseLeave?: () => void
}

export default function Text({ label, ...props }: TextProps) {
    return <StyledText {...props}>{label}</StyledText>
}

type TextTypes = 'heading1' | 'heading2' | 'heading3' | 'heading4' | 'subtitle' | 'subtitle2' | 'paragraph' | 'small'
type Weight = 'light' | 'normal' | 'semi-bold' | 'bold'
type Status = 'default' | 'alt' | 'info' | 'muted'
type Capitalization = 'none' | 'uppercase' | 'lowercase'

const getSize = (type: TextTypes = 'paragraph') => TYPES[type].size
const getWeight = (weight: Weight = 'normal') => WEIGHTS[weight]
const getLineHeight = (type: TextTypes = 'paragraph') => TYPES[type].lineHeight
const getStatus = (status: Status = 'default') => STATUS[status]
const getCapitalization = (capitalization: Capitalization = 'none') => CAPITALIZATION[capitalization]

const TYPES = {
    heading1: { size: '56px', lineHeight: '70px' },
    heading2: { size: '40px', lineHeight: '50px' },
    heading3: { size: '35px', lineHeight: '50px' },
    heading4: { size: '18px', lineHeight: '25px' },
    subtitle: { size: '25px', lineHeight: '25px' },
    subtitle2: { size: '16px', lineHeight: '22px' },
    paragraph: { size: '16px', lineHeight: '25px' },
    box: { size: '24px', lineHeight: '24px' },
    small: { size: '13px', lineHeight: '16px' }
}

const WEIGHTS = {
    light: '300',
    normal: '400',
    'semi-bold': '600',
    bold: '700'
}

const STATUS = {
    default: Color.White,
    alt: Color.Black,
    info: Color.PurpleAlt,
    muted: Color.GreyAlt
}

const CAPITALIZATION = {
    none: 'none',
    uppercase: 'uppercase',
    lowercase: 'lowercase'
}

const DefaultText = styled.span`
    font: inherit;
    color: ${STATUS.default};
    font-size: ${TYPES.paragraph.size};
    font-weight: ${WEIGHTS.normal};
    text-transform: ${CAPITALIZATION.none};
`

const StyledText = styled<any>(DefaultText)`
  color: ${({ status }) => getStatus(status)};
  font-size: ${({ type }) => getSize(type)};
  font-weight: ${({ weight }) => getWeight(weight)};
  text-transform: ${({ capitalization }) => getCapitalization(capitalization)};
  line-height: ${({ type }) => getLineHeight(type)};
`
