import styled from 'styled-components'
import React, { ReactNode } from 'react'

import { Color } from 'web/components/css/Styles'

import CloseIcon from 'web/resources/img/close.png'

export type ModalProps = {
  show: boolean
  width?: string
  height?: string
  children: ReactNode
  onClose?: () => void
}

export default function Modal({ show, width, height, children, onClose }: ModalProps) {
  return (
    <Dialog show={show}>
      <Background onClick={onClose} />
      <ModalBox width={width} height={height}>
        <Button>
          <img onClick={onClose} src={CloseIcon} alt={'Close'}></img>
        </Button>
        {children}
      </ModalBox>
    </Dialog>
  )
}

const ModalBox = styled.div<any>`
  position: absolute;
  box-sizing: border-box;
  padding: 100px;
  border-radius: 4px;
  background-color: ${Color.White};

  width: ${({ width }) => width || 'min(100vh - 48px, 700px)'};
  height: ${({ height }) => height || 'auto'};
  min-width: 296px;
  overflow: hidden;
`

const Dialog = styled.dialog<{ show: boolean }>`
  box-sizing: border-box;
  padding: 0;
  margin: 0;

  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  background-color: transparent;
  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 10;

  ${({ show }) => (show ? '' : 'pointer-events: none')};
  filter: opacity(${({ show }) => (show ? '1' : '0')});
  /* transition: 0.2s ease-in-out; */
`

const Background = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.9);
`


export const NoStyleButton = styled.button`
  box-sizing: border-box;
  padding: 0;
  border: 0;
  line-height: 0;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
  outline: none;
`

const Button = styled(NoStyleButton)`
  position: absolute;
  display: inline-block;
  right: 22px;
  top: 22px;

  img {
      width: 18px;
  }
`
