import React from 'react'
import styled from 'styled-components'
import { createGlobalStyle } from 'styled-components'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import { Color } from 'web/components/css/Styles';

import HomeScreen from 'web/screens/home/HomeScreen';
import FunctionalServicesScreen from 'web/screens/functional-services/FunctionalServicesScreen';
import ALMScreen from 'web/screens/alm/ALMScreen';

import Menu from 'web/components/Menu';
import Footer from 'web/components/Footer';


const routes: AppRoute[] = [
  {
    path: 'home',
    screen: HomeScreen
  },
  {
    path: 'functional-services',
    screen: FunctionalServicesScreen
  },
  {
    path: 'alm',
    screen: ALMScreen
  }
]

export default class App extends React.Component {
  public render() {

    return (
      <React.StrictMode>
        <GlobalStyle />
        <Router>
          <Menu></Menu>
          <AppContainer>
            <AppRoutes />
          </AppContainer>
          <Footer />
        </Router>
      </React.StrictMode >
    )
  }
}

const AppRoutes = () => {
  return (
    <Switch>
      <Redirect exact from="/" to={routes.length > 0 ? routes[0].path : ''} />

      {routes
        .map((route) => (
          <Route
            key={route.path}
            exact
            path={`/${route.path}`}
            render={(props: any) => (<route.screen {...props} />)}
          />
        ))}
    </Switch>
  )
}

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Raleway', sans-serif;
    background-color: ${Color.White};
    color: ${Color.White};
    height: 100vh;
    box-sizing: border-box;
    div {
      box-sizing: border-box;
    }
  }
`

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export type AppRoute = {
  path: string
  screen: any
}