import React from 'react'
import styled from 'styled-components'
import { Color, MaxWidth } from 'web/components/css/Styles'
import Text from 'web/components/widgets/Text'
import Check from 'web/components/widgets/Check'

import BlueCheckmark from 'web/resources/img/checkmark-blue.png'

export interface Props {
    title: string
    itemList: Array<string>
    children: React.ReactNode
}

export default function AboutTheSolution({ title, itemList, children }: Props) {
    return (
        <Background>
            <Container>
                <LeftSide>
                    <Text type={'heading2'} label={title} status={'alt'} weight={'bold'} />
                    {children}
                </LeftSide>
                <RightSide>
                    <Checklist>
                        {itemList
                            .map(item =>
                                <Check label={item} image={BlueCheckmark} />
                            )}
                    </Checklist>
                </RightSide>
            </Container>
        </Background>
    )
}

const Background = styled.div`
    background-color: ${Color.White};
`

const Container = styled.div`
    max-width: ${MaxWidth};
    display: flex;
    flex-direction: row;
    margin: auto;
    padding: 30px 0px;
`

const LeftSide = styled.div`
    display: flex;
    flex-direction: column;
    flex: 50%;
    span {
        margin: 10px 0;
    }
`

const RightSide = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 50%;
`

const Checklist = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
`
