
import React from 'react'
import styled from 'styled-components'
import Text from 'web/components/widgets/Text'
import { Color } from 'web/components/css/Styles'

export interface BoxProps {
    label: string
    lineColor?: string
    onClick?: () => void
}

export default function Box({ label, lineColor, ...props }: BoxProps) {
    return (
        <BoxElement {...props}>
            <Line color={lineColor} />
            <BoxContainer>
                <Text type={'box'} weight={'semi-bold'} label={label} status={'alt'} />
            </BoxContainer>
        </BoxElement>
    )
}

const DefaultDiv = styled.div``

const BoxElement = styled.div`
    width: 384px;
    height: 120px;
    background-color: ${Color.White};
    border: 1px solid #6b6b6b17;
    box-shadow: 4px 3px 3px #6b6b6b85;

    margin-right: 20px;
    margin-bottom: 20px;
`

const BoxContainer = styled.div`
    padding: 20px;
`

const Line = styled<any>(DefaultDiv)`
    border: 1px solid ${Color.Purple};
    border-color: ${({ lineColor }) => lineColor || Color.LightBlue};
    width: 35%;
`