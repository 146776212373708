import React, { useCallback, useRef, useState } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { History } from 'history'
import FillEmptySpaceDiv from 'web/components/FillEmptySpaceDiv'
import { Color, MaxWidth } from 'web/components/css/Styles'
import Text from 'web/components/widgets/Text'

import logo from 'web/resources/img/logo-diff.png'
import TalkToOurExperts from './TalkToOurExperts'
import uiStrings from 'web/uiStrings'
import MenuPopover from 'web/components/widgets/MenuPopover'
import { ALMMenu, Development, FunctionalServiceMenu, InfrastructureAndTechnology, StrategicConsulting, Company } from './MenuSections'
import { onlyIf } from 'web/reactHelpers'

export default function Menu() {
    const history: History = useHistory()

    const FunctionalRef = useRef();
    const InfrastructureAndTechnologyRef = useRef();
    const ALMRef = useRef();
    const DevelopmentRef = useRef();
    const StrategicConsultingRef = useRef();
    const CompanyRef = useRef();

    const [sections, setSections] = useState(EmptyMenu)
    const [reference, setReference] = useState(FunctionalRef)

    const onClose = useCallback(() => {
        setSections(EmptyMenu)
    }, [])

    return (
        <MenuContainer>
            <LogoContainer><img src={logo} alt="logo" onClick={() => history.push('/')} /></LogoContainer>

            <FillEmptySpaceDiv />

            <Navigation
                onMouseLeave={() => {
                    onClose();
                }}>
                <NavItem ref={FunctionalRef}>
                    <div>
                        <MenuItem label={uiStrings.MENU.FUNCTIONAL_SERVICES} onMouseEnter={() => {
                            setSections(FunctionalServiceMenu)
                            setReference(FunctionalRef)
                        }} type={'small'} weight={'bold'} capitalization={'uppercase'} />
                    </div>
                </NavItem>
                <NavItem ref={ALMRef}>
                    <div>
                        <MenuItem label={uiStrings.MENU.ALM} onMouseEnter={() => {
                            setSections(ALMMenu)
                            setReference(ALMRef)
                        }} type={'small'} weight={'bold'} capitalization={'uppercase'} />
                    </div>
                </NavItem>
                <NavItem ref={DevelopmentRef}>
                    <div>
                        <MenuItem label={uiStrings.MENU.DEVELOPMENT} onMouseEnter={() => {
                            setSections(Development)
                            setReference(DevelopmentRef)
                        }} type={'small'} weight={'bold'} capitalization={'uppercase'} />
                    </div>
                </NavItem>
                <NavItem ref={InfrastructureAndTechnologyRef}>
                    <div>
                        <MenuItem label={uiStrings.MENU.INFRASTRUCTURE_TECHNOLOGY} onMouseEnter={() => {
                            setSections(InfrastructureAndTechnology)
                            setReference(InfrastructureAndTechnologyRef)
                        }} type={'small'} weight={'bold'} capitalization={'uppercase'} />
                    </div>
                </NavItem>
                <NavItem ref={StrategicConsultingRef}>
                    <div>
                        <MenuItem label={uiStrings.MENU.STRATEGIC_CONSULTING} onMouseEnter={() => {
                            setSections(StrategicConsulting)
                            setReference(StrategicConsultingRef)
                        }} type={'small'} weight={'bold'} capitalization={'uppercase'} />
                    </div>
                </NavItem>
                <NavItem ref={CompanyRef}>
                    <div>
                        <MenuItem label={uiStrings.MENU.COMPANY} onMouseEnter={() => {
                            setSections(Company)
                            setReference(CompanyRef)
                        }} type={'small'} weight={'bold'} capitalization={'uppercase'} />
                    </div>
                </NavItem>
                
                <TalkToOurExpertsContainer onMouseEnter={onClose}>
                    <TalkToOurExperts />
                </TalkToOurExpertsContainer>

                <MenuContent onClose={onClose} sections={sections} reference={reference} />
            </Navigation>

        </MenuContainer >
    )
}

function MenuContent({ sections, onClose, reference }: { sections: MenuSections, onClose: () => void, reference: any }) {
    const history: History = useHistory()

    const menuArrowPosition = calculateMenuArrowPosition(reference)
    const menuWidth = getMenuWidth(sections)
    const menuPopoverLeftPosition = calculateMenuPopoverLeftPosition(menuArrowPosition, menuWidth)

    return (
        <MenuPopover show={sections.length > 0} onClose={onClose} width={menuWidth + 'px'} height={sections.length === 1 ? 'auto' : '560px'} left={menuPopoverLeftPosition} arrow={menuArrowPosition}>
            <MenuPopoverContent>
                {sections.map(section => (
                    <MenuColumn key={section.name}>
                        <SectionHeader label={section.name} type={'small'} status={'alt'} weight={'bold'} capitalization={'uppercase'} />
                        {section.sub_sections?.map(subSection => (
                            <>
                                <MenuOption label={subSection.name} onClick={() => navigate(subSection.link || '', history, onClose)} type={'subtitle2'} weight={'bold'} status={'info'} />
                                {onlyIf(!!subSection.description, <Description label={subSection.description} type={'small'} status={'muted'} />)}
                            </>
                        ))}
                    </MenuColumn>
                ))}
            </MenuPopoverContent>
        </MenuPopover>)
}

function calculateMenuArrowPosition(reference: any): number {
    let menuArrowPosition = 0
    if (reference.current) {
        menuArrowPosition = reference.current.offsetLeft
        menuArrowPosition += reference.current.offsetWidth / 2
    }
    return menuArrowPosition
}

function getMenuWidth(sections: MenuSections): number {
    let columns = 0;
    for (let section of sections) {
        columns = Math.max(section.column, columns)
    }
    return columns * 300
}

function calculateMenuPopoverLeftPosition(menuArrowPosition: number, menuWidth: number): number {
    let menuPopoverLeftPosition = menuArrowPosition - menuWidth / 2

    if (menuWidth + menuPopoverLeftPosition > window.innerWidth) {
        menuPopoverLeftPosition = window.innerWidth - menuWidth
    }
    return menuPopoverLeftPosition
}

function navigate(link: string, history: History, onClose: () => void) {
    history.push(link)
    onClose()
}

const EmptyMenu: MenuSections = [];

export type MenuSections = Array<{
    name: string,
    column: number,
    sub_sections?: Array<{
        name: string,
        description?: string,
        link?: string
    }>
}>


const MenuContainer = styled.div`
    max-width: ${MaxWidth};
    height: 90px;
    box-sizing: border-box;
    display: flex;
    margin: auto;
    position: absolute;
    left: 0;
    right: 0;
`

const LogoContainer = styled.div`
    align-items: center;
    justify-content: center;
    display: flex;
    cursor: pointer;
    img {
        padding: 10px 0;
        max-width:120px;
    }
`

const Navigation = styled.div`
    display:flex;
`

const EmptyDiv = styled.div``

const NavItem = styled<any>(EmptyDiv)`
    display: flex;
    align-items: center;
    margin: 0 6px;
`

const MenuItem = styled(Text)`
    :hover {
        cursor: pointer;
    }
`

const TalkToOurExpertsContainer = styled.div`
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
`

const MenuPopoverContent = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 100%;
`

const MenuColumn = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 0 30px;
    width: calc(300px - 60px);
`

const SectionHeader = styled(Text)`
    padding-top: 30px;
    padding-bottom: 20px;
`

const MenuOption = styled(Text)`
    padding: 10px 0;
    :hover {
        margin-left: -10px;
        padding-left: 10px;
        margin-right: -10px;
        padding-right: 10px;
        cursor: pointer;
        background: ${Color.PurpleAltTransparent};
        border-radius: 10px;
    }
`

const Description = styled(Text)`
    margin: 10px 0;
`