import React from 'react'
import styled from 'styled-components'

import { Color } from 'web/components/css/Styles'

export type TextInputProps = {
    placeholder?: string
    type?: string
    value?: any
    disabled?: boolean
    readonly?: boolean
    required?: boolean
    error?: boolean
    success?: boolean
    maxLength?: number

    onBlur?: (e?: any) => void
    onChange?: (e?: any) => void
}

enum Mode {
    Normal,
    Success,
    Error
}

export default function TextInput({ disabled, readonly, success, error, ...props }: TextInputProps) {
    const mode = success ? Mode.Success : error ? Mode.Error : Mode.Normal
    return (
        <Container>
            <Input
                {...props}
                readOnly={readonly}
                mode={mode}
            />
        </Container>
    )
}

const Input = styled.input<any>`
  appearance: none;
  outline: none;
  box-shadow: none;

  width: 100%;
  height: 38px;
  box-sizing: border-box;

  padding: 0 12px;
  border: 0.5px solid ${Color.GreyTransparent};
  border-radius: 3px;

  background: ${Color.Transparent};
  color: ${Color.Black};

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
  font-weight: 300;

  ${({ mode }) => (mode === Mode.Success ? `border: 0.5px solid ${Color.Green}` : '')};
  ${({ mode }) => (mode === Mode.Error ? `border: 0.5px solid ${Color.Red}` : '')};
  ${({ mode }) => (mode === Mode.Error ? `color: ${Color.Red}` : '')};

`

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`
