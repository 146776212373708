import styled from 'styled-components'
import React, { ReactNode } from 'react'

import { Color } from 'web/components/css/Styles'

import ArrowImage from 'web/resources/img/menu-arrow.png'

export type MenuPopoverProps = {
    show: boolean
    width?: string
    height?: string
    left?: number
    arrow?: number
    children: ReactNode
    onClose?: () => void
}

export default function MenuPopover({ show, width, height, left, arrow, children, onClose }: MenuPopoverProps) {
    return (
        <>
            <ArrowContainer show={show} arrow={arrow} >
                <img src={ArrowImage} width={30} alt="" />
            </ArrowContainer >
            <ModalBox show={show} width={width} height={height} left={left}>
                {children}
            </ModalBox>
        </>
    )
}

const ArrowContainer = styled.div<any>`
    position: absolute;
    top: 67px;

    left: ${({ arrow }) => ((arrow - 15) + 'px') || '190px'};

    filter: opacity(${({ show }) => (show ? '1' : '0')});
    transition: 0.2s ease-in-out;
`

const ModalBox = styled.div<any>`
    position: absolute;
    box-sizing: border-box;
    border-radius: 4px;
    background-color: ${Color.White};

    width: ${({ width }) => width || 'min(80vw, 985px)'};
    height: ${({ height }) => height || 'auto'};
    /* min-width: 296px; */
    max-height: 560px;
    overflow: hidden;

    top: 80px;
    left: ${({ left }) => (left + 'px') || '190px'};

    padding-bottom: 30px;

    ${({ show }) => (show ? '' : 'pointer-events: none')};
    filter: opacity(${({ show }) => (show ? '1' : '0')});
    transition: 0.2s ease-in-out;
`