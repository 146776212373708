import React, { useCallback, useState } from 'react'
import styled from 'styled-components'

import Button, { ButtonModes } from 'web/components/widgets/Button'
import Text from 'web/components/widgets/Text'
import Input from 'web/components/widgets/Input'
import Modal from 'web/components//widgets/Modal'
import uiStrings from 'web/uiStrings'
import Field from './widgets/Field'

export interface Props {
    mode?: ButtonModes
}

export default function TalkToOurExperts({ mode }: Props) {
    const [show, setShow] = useState(false)

    const onClose = useCallback(() => {
        setShow(false)
    }, [])

    return (
        <TalkToOurExpertsContaner>
            <Button label={uiStrings.TALK_TO_OUR_EXPERTS} onClick={() => setShow(true)} mode={mode || 'normal'} />

            <Modal show={show} onClose={onClose}>
                <TitleContainer>
                    <Text type={'heading2'} status={'info'} weight={'bold'} label={uiStrings.TALK_TO_OUR_EXPERTS}></Text>
                </TitleContainer>
                <SubtitleContainer>
                    <Text type={'heading4'} status={'muted'} label={uiStrings.PLEASE_FILL_OUT_THE_FORM}></Text>
                </SubtitleContainer>

                <ContactForm>
                    <ContactFormRow>
                        <InputContaner>
                            <Field label={uiStrings.FIRST_NAME}>
                                <Input type={'text'} required maxLength={50} />
                            </Field>
                        </InputContaner>
                        <InputContaner>
                            <Field label={uiStrings.LAST_NAME}>
                                <Input type={'text'} required maxLength={50} />
                            </Field>
                        </InputContaner>
                    </ContactFormRow>

                    <ContactFormRow>
                        <InputContaner>
                            <Field label={uiStrings.COMPANY_EMAIL}>
                                <Input type={'text'} required maxLength={50} />
                            </Field>
                        </InputContaner>
                        <InputDivider />
                        <InputContaner>
                            <Field label={uiStrings.PHONE_NUMBER}>
                                <Input type={'text'} required maxLength={20} />
                            </Field>
                        </InputContaner>
                    </ContactFormRow>

                    <ContactFormRow>
                        <InputFullWidthContaner>
                            <Field label={uiStrings.COMPANY_NAME}>
                                <Input type={'text'} required maxLength={50} />
                            </Field>
                        </InputFullWidthContaner>
                    </ContactFormRow>

                    <ContactFormRow>
                        <InputFullWidthContaner>
                            <Field label={uiStrings.MESSAGE}>
                                <Input type={'text'} required maxLength={50} />
                            </Field>
                        </InputFullWidthContaner>
                    </ContactFormRow>
                </ContactForm>

                <SubmitButtonContainer>
                    <Button label={uiStrings.SUBMIT} onClick={() => setShow(false)} />
                </SubmitButtonContainer>
            </Modal>
        </TalkToOurExpertsContaner>
    )
}

const TalkToOurExpertsContaner = styled.div`
`

const ContactForm = styled.div`
    display: flex;
    flex-direction: column;
`

const ContactFormRow = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
`

const InputDivider = styled.div`
    flex: 1;
`

const TitleContainer = styled.div`
    text-align: center;
`

const SubtitleContainer = styled.div`
    margin: 30px 0;
    text-align: center;
`

const InputContaner = styled.div`
    width: 49%;
`

const InputFullWidthContaner = styled.div`
    width: 100%;
`

const SubmitButtonContainer = styled.div`
    margin-top: 30px;
`