import React from 'react'
import styled from 'styled-components'
import { MaxWidth } from 'web/components/css/Styles'
import Text from 'web/components/widgets/Text'

import BackgroundBanner from 'web/resources/img/bg-banner-2.png'

export interface Props {
    text: string
}

export default function Banner({ text }: Props) {
    return (
        <Container>
            <BannerContainer>
                <Text type={'heading3'} weight={'bold'} label={text} />
            </BannerContainer>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    height: 250px;
    background: url(${BackgroundBanner});
`

const BannerContainer = styled.div`
    max-width: ${MaxWidth};
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin: auto;
    span {
        max-width: 80%;
        margin: 14px 0;
    }
`